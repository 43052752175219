<template>
  <div class="text">
    <p>Access Forbidden, you can't access this functionality.</p>
    <p>Please click on logout & login again as company user</p>
    <el-button type="primary" class="mt-1" @click="logoutUser"
      >Logout</el-button
    >
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import config from "../../config/app";
export default {
  data() {
    return {
      companyDataLocal: {},
    };
  },
  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveContactType"]),
    ...mapGetters("company", ["getContactLoginSlug"]),
  },
  async mounted() {
    if (this.getContactLoginSlug && this.getContactLoginSlug._id) {
      this.companyDataLocal = { ...this.getContactLoginSlug };
    }
  },
  methods: {
    logoutUser() {
      Swal.fire({
        title: "",
        html: "signing off....",
        timer: 3000,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
          Swal.showLoading();
          localStorage.removeItem(config.APP_NAME + "_" + config.APP_VERSION);
          localStorage.clear();
          this.resetStore();
        },
        willClose: () => {
          if (this.companyDataLocal?.slug) {
            this.$router.push(
              `/ap/signin?company=${this.companyDataLocal.slug}`
            );
          } else {
            this.$router.push("/signin");
          }
        },
      });
      this.resetStore();
    },
    resetStore() {
      this.$store.commit("auth/resetAuthData");
      this.$store.commit("profile/resetProfileData");
      this.$store.commit("company/resetCompanyData");
      this.$store.commit("auth/resetAuthData", null, { root: true });
      this.$store.commit("company/resetCompanyData", null, { root: true });
      this.$store.commit("companyDocuments/resetCompanyDocumentsData", null, {
        root: true,
      });
      this.$store.commit("documentGroups/resetDocumentGroupsData", null, {
        root: true,
      });
      this.$store.commit(
        "documentCategories/resetDocumentCategoriesData",
        null,
        {
          root: true,
        }
      );

      this.$store.commit("companyBenefits/resetCompanyBenefitsData", null, {
        root: true,
      });
      this.$store.commit("companyTemplates/resetCompanyTemplatesData", null, {
        root: true,
      });
      this.$store.commit("offerLetter/resetOfferLetterData", null, {
        root: true,
      });
      this.$store.commit("employee/resetEmployeesData", null, {
        root: true,
      });
      this.$store.commit("configurableDocuments/resetConfigurableDocuments");

      this.$store.commit("standardDocumentConfig/reset");
      this.$store.commit("employeeData/reset");
      this.$store.commit("employeeDocuments/reset");
      this.$store.commit("workflowData/reset");
      this.$store.commit("workflows/reset");
      this.$store.commit("templatesData/reset");
      this.$store.commit("i9formDocuments/reset");
      this.$store.commit("authorizedRepresentatives/reset");
      this.$store.commit("employeeDocumentAuthorizedUsers/reset");
    },
  },
};
</script>
<style scoped>
.text {
  font-size: 18px;
  color: red;
  height: 100vh;
  margin-top: 30vh;
  /* display: flex; */
  text-align: center;
}
</style>
